import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

type StyleProps = {
    width?: string;
    heigth?: string;
    themecolor?: string;
    disablebtn?: string;
};

const ColorButton = styled(Button)<StyleProps>((theme) => ({
    boxShadow: "none",
    color: theme.themecolor === "white" ? "#6C6C6C" : "#FFFFFF",
    backgroundColor: theme.themecolor === "white" ? "#FFFFFF" : "#661FEA",
    width: theme.width,
    height: theme.heigth,
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "0px",
    border: theme.themecolor === "white" ? "1px solid #EAEAEA" : "none",
    cursor: theme.disablebtn === "true" ? "default" : "pointer",
    "&:hover": {
        backgroundColor:
            theme.themecolor !== "white"
                ? theme.disablebtn === "true"
                    ? "#661FEA"
                    : "#661fea96"
                : "#E5E5E5",
    },
    "&:disabled": {
        backgroundColor: "#6630E5",
        opacity: 0.3,
        color: "#FFFFFF",
    },
}));

type PropsType = {
    width?: string;
    height?: string;
    children?: any;
    sx?: any;
    themecolor?: string;
    onClick?: any;
    disabled?: boolean;
    disablebtn?: string;
    style?: any;
};

const ComponentShadow: React.FunctionComponent<PropsType> = (props) => {
    return (
        <ColorButton
            variant="contained"
            width={props.width}
            heigth={props.height}
            themecolor={props.themecolor}
            disablebtn={props.disablebtn}
            disableRipple={props.disablebtn === "true" ? true : false}
            {...props}
        >
            {props.children}
        </ColorButton>
    );
};

ComponentShadow.defaultProps = {
    disablebtn: "false",
};

export default ComponentShadow;
