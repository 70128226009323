import React from "react";
import PrimaryButton from "../button/PrimaryButton";

type ButtonType = {
    id: number | string;
    width: string;
    height: string;
    themecolor?: string;
    sx?: object;
    text?: any;
    onclick?: any;
    print?: any;
    disabled?: boolean;
    disablebtn?: string;
    afterFunction?: any;
};

const ComponentShadow = ({
    buttonGroup,
    minWidth,
}: {
    buttonGroup: Array<ButtonType>;
    minWidth?: string;
}) => {
    return (
        <div style={{ maxWidth: minWidth ? minWidth : "910px", marginTop: "-10px" }}>
            {buttonGroup.map((row, index) =>
                row.print ? (
                    <span key={index}>
                        <PrimaryButton
                            width={row.width}
                            height={row.height}
                            sx={row.sx}
                            themecolor={row.themecolor}
                            style={{ marginTop: "10px" }}
                            onClick={row.onclick}
                        >
                            {row.text}
                        </PrimaryButton>
                    </span>
                ) : (
                    <PrimaryButton
                        key={index}
                        width={row.width}
                        height={row.height}
                        sx={row.sx}
                        themecolor={row.themecolor}
                        onClick={row.onclick}
                        disabled={row.disabled}
                        disablebtn={row.disablebtn}
                        style={{ marginTop: "10px" }}
                    >
                        {row.text}
                    </PrimaryButton>
                )
            )}
        </div>
    );
};

export default ComponentShadow;
