import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PrimaryButton from "../../button/PrimaryButton";
import AlertDialog from "../AlertDialog";
import { PutApi } from "../../../api/apiService";
import { KEY_ENTER } from "../../../utils/help";
import { useRecoilValue } from "recoil";
import { storageRegisterState } from "../../../context/storageRegister";

interface ListItemProps {
    size?: string;
}

const StorageRegister = (props: any) => {
    const [storageInputID, setStorageInputID] = useState<string>("");
    const [itemID, setItemID] = useState<any>([]);
    const itemInputRef = useRef<any>();
    const registerListBoxRef = useRef<HTMLDivElement | null>(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [fail, setFail] = useState(false);
    const handleAlertClose = () => {
        setAlertOpen(false);
        if (!fail) {
            handleClose();
        }
    };

    const handleClose = () => {
        setStorageInputID("");
        props.handleClose();
    };

    // 스토리지 ID 입력
    const keyDownFunction = (e: any) => {
        if (e.keyCode === KEY_ENTER) {
            setStorageInputID(e.target.value);
            itemInputRef.current.focus();
        }
    };

    // Item ID 입력
    const itemKeyDownFunction = (e: any) => {
        if (e.keyCode === KEY_ENTER) {
            const temp = parseInt(e.target.value);
            if (itemID.includes(temp)) {
                setItemID(itemID.filter((id: number) => id !== temp));
            } else {
                setItemID((itemID: any) => [...itemID, temp]);
            }
            e.target.value = "";
        }
    };

    const register = () => {
        const url = props.apiUrl ? props.apiUrl : "/api/logis/storage/item/import";
        const data = {
            storageID: storageInputID,
            itemIDs: itemID.map(function (i: string) {
                return parseInt(i, 10);
            }),
        };
        PutApi(url, data)
            .then((response) => {
                if (response.statusCode === 200) {
                    let registeredStorage = response.items.map((item: any) => {
                        return item.storageID;
                    });
                    registeredStorage = [...new Set(registeredStorage)];
                    setAlertText(
                        `${registeredStorage} 스토리지에 ${response.items.length}벌이  등록되었습니다.`
                    );
                    setAlertOpen(true);
                    setFail(false);
                    if (props.handleRefresh) {
                        setStorageInputID("");
                        props.handleRefresh();
                    }
                } else {
                    setAlertText("스토리지 등록에 실패하였습니다.");
                    setAlertOpen(true);
                }
            })
            .catch((error) => {
                console.warn(error);
                setAlertText("스토리지 등록중 오류가 발생했습니다.");
                setAlertOpen(true);
                setFail(true);
            });
    };

    const submitStorageItem = () => {
        register();
    };

    useEffect(() => {
        if (props.open) {
            setItemID([]);
        }
    }, [props.open]);

    useEffect(() => {
        registerListBoxRef.current?.scrollTo(0, registerListBoxRef.current?.scrollHeight);
    }, [itemID, registerListBoxRef]);

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                <Title>스토리지 등록</Title>
                <Lable>Storage ID</Lable>
                <InputSpace>
                    {storageInputID === "" ? (
                        <StorageInput
                            type="text"
                            onKeyDown={(e) => keyDownFunction(e)}
                            autoFocus
                            placeholder="스토리지 QR code를 스캔하면 자동입력 됩니다."
                        />
                    ) : (
                        <StorageInput type="text" readOnly />
                    )}
                </InputSpace>
                <Lable>
                    {"Item ID "}
                    {itemID.length > 0 && (
                        <span style={{ color: "#661FEA", paddingRight: "40px" }}>{itemID.length}</span>
                    )}
                </Lable>
                <ListBox ref={registerListBoxRef}>
                    {itemID.length === 0 ? (
                        <List>
                            <ListItemStyle size="30px">
                                <TextItemTextStyle>
                                    상품포장 또는 옷걸이에 부착된 QR code를 스캔하면 자동입력 됩니다.
                                    <br />
                                    동일한 스토리지 구역에 등록할 상품이 여러개일 경우, QR code를
                                    연속해서 스캔해주세요.
                                </TextItemTextStyle>
                            </ListItemStyle>
                        </List>
                    ) : (
                        <List>
                            <ListItemStyle size="21px">
                                <ListItemTextStyle>
                                    {itemID.map((items: string, index: number) => (
                                        <div key={index}>
                                            <span style={{ color: "#661FEA", paddingRight: "40px" }}>
                                                {index + 1}
                                            </span>
                                            <span>{items}</span>
                                        </div>
                                    ))}
                                </ListItemTextStyle>
                            </ListItemStyle>
                        </List>
                    )}
                </ListBox>
                <ItemInput
                    type="text"
                    ref={itemInputRef}
                    onKeyDown={(e) => itemKeyDownFunction(e)}
                ></ItemInput>
                <ButtonBox>
                    {itemID.length === 0 ? (
                        <PrimaryButton width="170px" height="40px" disabled>
                            등록완료
                        </PrimaryButton>
                    ) : (
                        <PrimaryButton width="170px" height="40px" onClick={submitStorageItem}>
                            등록완료
                        </PrimaryButton>
                    )}
                </ButtonBox>
                <AlertDialog open={alertOpen} handleClose={handleAlertClose} confirm={false}>
                    {alertText}
                </AlertDialog>
            </ModalBox>
        </Modal>
    );
};

const StroageRegisterMdoal = () => {
    const storageRegister = useRecoilValue(storageRegisterState);

    if (!storageRegister) return <></>;

    return (
        <StorageRegister
            open
            handleClose={storageRegister.onClickClose}
            handleRefresh={storageRegister.handleRefresh}
        />
    );
};

export default StroageRegisterMdoal;

const ModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    background-color: #ffffff;
    box-shadow: none;
    border: 1px solid #a9a9a9;

    @media (max-width: 550px) {
        width: 70vw;
    }
`;

const ListBox = styled(Box)`
    width: 90%;
    height: 400px;
    background-color: #ffffff;
    border: 0.5px solid #a9a9a9;
    margin: 0 auto;
    overflow: scroll;

    @media (max-width: 550px) {
        height: 200px;
    }

    @media (max-height: 600px) {
        height: 200px;
    }
`;

const ListItemStyle = styled(ListItem)<ListItemProps>`
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: ${(props) => props.size || "20px"};
    padding-right: ${(props) => props.size || "20px"};
`;
/*
const ListItemStorage = styled(ListItem)`
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
`

const StorageBox = styled(List)<StorageBoxProps>`
    width: 90%;
    background-color: ${props => props.bgColor};
    border: 0.5px solid #A9A9A9;
    margin: 0 auto;
    margin-bottom: 40px;
    padding-top: 1px;
    padding-bottom: 1px;
`
*/

const Lable = styled("p")`
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    color: #6c6c6c;
`;

const Title = styled("p")`
    text-align: center;
    font-size: 25px;
    font-weight: 800;
    color: #661fea;
    margin-bottom: 30px;
`;

const ButtonBox = styled("div")`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ListItemTextStyle = styled(ListItemText)`
    color: #6c6c6c;
    font-weight: 500;
`;

const TextItemTextStyle = styled(ListItemText)`
    color: #6c6c6c;
    font-weight: 500;

    & > span {
        line-height: 32px;
    }
`;

const StorageInput = styled("input")`
    width: 100%;
    height: 30px;
    color: #6c6c6c;
    font-weight: 500;
    border: 0.5px solid #a9a9a9;
    padding: 0px 8px;

    :focus {
        color: transparent;
        text-shadow: 0 0 0 black;
        border: #661fea;
    }
`;

const ItemInput = styled("input")`
    width: 0px;
    height: 0px;
    color: transparent;
    border: none;
    font-weight: 500;
    padding: 0px 0px;

    :focus {
        color: transparent;
        border: none;
    }
`;

const InputSpace = styled("div")`
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
`;
