// material-ui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// ==============================|| DRAWER HEADER - STYLED ||============================== //

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }: { theme: any; open: any }) => ({
        ...theme.mixins.toolbar,
        display: "inline",
        alignItems: "center",
        justifyContent: open ? "flex-start" : "center",
        // paddingLeft: theme.spacing(open ? 3 : 0),
        fontSize: "30px",
        lineHeigh: "5px",
        fontWeight: 700,
        paddingLeft: "25px",
        paddingTop: "55px",
        paddingBottom: "55px",
        color: "#47443D",
        // borderBottom: '0.5px solid #C4C4C4'
    })
);

export default DrawerHeaderStyled;
