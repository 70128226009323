// project import
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import "./fonts/happinessSans/ttf/Happiness-Sans-Bold.ttf";
import "./fonts/happinessSans/ttf/Happiness-Sans-Regular.ttf";
import "./fonts/happinessSans/ttf/Happiness-Sans-Title.ttf";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { Alert } from "./components/Alert";
import StorageExportModal from "./components/modal/storage/StorageExport";
import StroageRegisterMdoal from "./components/modal/storage/StorageRegister";
import { ModalProvider } from "react-modal-hook";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    const queryClient = new QueryClient();

    return (
        <ModalProvider>
            <ThemeCustomization>
                <QueryClientProvider client={queryClient}>
                    <RecoilRoot>
                        <ScrollTop>
                            <Routes />
                            <Alert />
                            <StroageRegisterMdoal />
                            <StorageExportModal />
                        </ScrollTop>
                    </RecoilRoot>
                </QueryClientProvider>
            </ThemeCustomization>
        </ModalProvider>
    );
};

export default App;
