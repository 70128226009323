import PropTypes from "prop-types";
import { useTheme, Typography } from "@mui/material";
// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }: { open: any }) => {
    const theme = useTheme();

    return (
        // only available in paid version
        <>
            <DrawerHeaderStyled theme={theme} open={open}>
                <div>{"차란"}</div>
                <div>{"CORE"}</div>
                {process.env.REACT_APP_ENV !== "PROD" && <div>{process.env.REACT_APP_ENV}</div>}
            </DrawerHeaderStyled>
            <Typography variant="h5" marginLeft={"30px"}>
                {process.env.REACT_APP_VER}
            </Typography>
        </>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool,
};

export default DrawerHeader;
