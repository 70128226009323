import axios, { AxiosResponse } from "axios";
import { getCookie, removeCookie } from "../store/Cookie";
import { USER_INFO_COOKIE_KEY } from "../constant";

const config = {
    // api: process.env.API_SERVER,
    api: "", // proxy 사용시 이것을 이용합니다.
    options: {
        headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + getCookie("accessToken"),
        },
    },
};

async function httpGet(endpoint: string) {
    config.options.headers.Authorization = "Bearer " + getCookie("accessToken");
    const response = await axios({
        method: "get",
        url: `${config.api}${endpoint}`,
        ...config.options,
    });
    return handleResponse(response);
}

async function httpPost(endpoint: string, data: any) {
    config.options.headers.Authorization = "Bearer " + getCookie("accessToken");
    const response = await axios({
        method: "post",
        url: `${config.api}${endpoint}`,
        data: data,
        ...config.options,
    });
    return handleResponse(response);
}

async function httpPut(endpoint: string, data: any) {
    config.options.headers.Authorization = "Bearer " + getCookie("accessToken");
    const response = await axios({
        method: "put",
        url: `${config.api}${endpoint}`,
        data: data,
        ...config.options,
    });
    return handleResponse(response);
}

async function httpDelete(endpoint: string) {
    const response = await axios({
        method: "delete",
        url: `${config.api}${endpoint}`,
        ...config.options,
    });
    return handleResponse(response);
}

const handleResponse = (response: AxiosResponse<any, any>) => {
    if (response.status === 200) {
        return response.data;
    } else {
        alert(response.statusText);
        throw Error(response.statusText);
    }
};

// AxiosError는 catch에서 발생함.
const Unauthrized = (error: any) => {
    if (error.response.status === 401) {
        // unauthrized
        alert(error.message);
        removeCookie(USER_INFO_COOKIE_KEY, { path: "/" });
        removeCookie("accessToken", { path: "/" });
        location.href = "/";
    }
};

const PostApi = async (url: string, data: any) => {
    let response;
    try {
        response = await httpPost(url, data);
    } catch (error: any) {
        console.warn(error);
        // Unauthrized(error);
        throw error;
    }
    return response;
};

const PutApi = async (url: string, data: any) => {
    let response;
    try {
        response = await httpPut(url, data);
    } catch (error: any) {
        console.warn(error);
        // Unauthrized(error);
    }
    return response;
};

const GetApi = async (url: string, param = "") => {
    let response;
    try {
        response = await httpGet(url + param);
        if (response.statusCode === 400) {
            alert(`오류 : ${response.optionalMessage}. (code=${response.optionalCode})`);
        }
    } catch (error: any) {
        console.warn(error);
        Unauthrized(error);
    }
    return response;
};

export { httpGet, httpPost, httpPut, httpDelete, GetApi, PutApi, PostApi };
