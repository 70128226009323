import * as React from "react";
import Modal from "@mui/material/Modal";
import { ModalBox, ModalTitle } from "./ModalStyled";
import PrimaryButton from "../button/PrimaryButton";
import TableButtonGroup from "../table/TableButtonGroup";

const DEFAULT_ALERT_Z_INDEX = 9999999999999;

const ComponentShadow = (props: any) => {
    const ButtonGroup = [
        {
            id: 2,
            width: "127px",
            height: "33",
            themecolor: "white",
            sx: { marginRight: "10px" },
            text: "취소",
            onclick: props.handleClose,
        },
        { id: 1, width: "127px", height: "33", text: "확인", onclick: props.handleConfirm },
    ];

    const ButtonGroupPrint = [
        {
            id: 2,
            width: "127px",
            height: "33",
            themecolor: "white",
            sx: { marginRight: "10px" },
            text: "취소",
            onclick: props.handleClose,
        },
        {
            id: 1,
            width: "127px",
            height: "33",
            text: "확인",
            onclick: props.handleConfirm,
            afterFunction: props.handleClose,
        },
    ];

    return (
        <Modal
            sx={{ zIndex: DEFAULT_ALERT_Z_INDEX }}
            open={props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                {props.showImg && props.src && <img src={props.src} />}
                <ModalTitle dangerouslySetInnerHTML={{ __html: props.children }}></ModalTitle>
                {props.confirm ? (
                    props.print ? (
                        <TableButtonGroup buttonGroup={ButtonGroupPrint} />
                    ) : (
                        <TableButtonGroup buttonGroup={ButtonGroup} />
                    )
                ) : (
                    <PrimaryButton
                        width="127px"
                        height="33"
                        themecolor="white"
                        onClick={props.handleBtn ? props.handleBtn : props.handleClose}
                    >
                        확인
                    </PrimaryButton>
                )}
            </ModalBox>
        </Modal>
    );
};

export default ComponentShadow;
