// project import
import main from "./main";
import fm from "./fm";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [main],
    fmItems: [fm],
};

export default menuItems;
