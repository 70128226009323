import axios from "axios";
import { httpGet } from "./apiService";

export type measureSizeType = "total" | "sleeve" | "shoulder" | "breast" | "waist" | "hip";

export interface MeasureSizeCat {
    coreDPName: string;
    providerDPName: string;
    shopperDPName: string;
    coreDescription: string;
    providerDescription: string;
    shopperDescription: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    id: measureSizeType;
}
export interface MetaDataState {
    charanBagOrderStatus: { [key: string]: any };
    charanBagStatus: { [key: string]: any };
    collectionStatus: { [key: string]: any };
    commission: { [key: string]: any };
    deliveryStatus: { [key: string]: any };
    donationStatus: { [key: string]: any };
    donorOrg: { [key: string]: any };
    itemBrand: { [key: string]: any };
    itemBrandCat: { [key: string]: any };
    itemCondition: { [key: string]: any };
    itemDpSize: { [key: string]: any };
    itemDpSizeCat: { [key: string]: any };
    itemMainCat: { [key: string]: any };
    itemMaterial: { [key: string]: any };
    itemMeasureSizeCat: MeasureSizeCat[];
    itemSeasonality: { [key: string]: any };
    itemStatus: { [key: string]: any };
    itemStyleCat: { [key: string]: any };
    itemSubCat: { [key: string]: any };
    itemUdBrand: { [key: string]: any };
    itemUdDpSize: { [key: string]: any };
    itemUdStyleCat: { [key: string]: any };
    orderStatus: { [key: string]: any };
    paymentStatus: { [key: string]: any };
    paymentType: { [key: string]: any };
    returningStatus: { [key: string]: any };
    settlementStatus: { [key: string]: any };
    typeBank: { [key: string]: any };
    typeCreditChanged: { [key: string]: any };
    kgSellingStatus: { [key: string]: any };
}

const MetaData: MetaDataState = {
    charanBagOrderStatus: [],
    charanBagStatus: [],
    collectionStatus: [],
    commission: [],
    deliveryStatus: [],
    donationStatus: [],
    donorOrg: [],
    itemBrand: [],
    itemBrandCat: [],
    itemCondition: [],
    itemDpSize: [],
    itemDpSizeCat: [],
    itemMainCat: [],
    itemMaterial: [],
    itemMeasureSizeCat: [],
    itemSeasonality: [],
    itemStatus: [],
    itemStyleCat: [],
    itemSubCat: [],
    itemUdBrand: [],
    itemUdDpSize: [],
    itemUdStyleCat: [],
    orderStatus: [],
    paymentStatus: [],
    paymentType: [],
    returningStatus: [],
    settlementStatus: [],
    typeBank: [],
    typeCreditChanged: [],
    kgSellingStatus: [],
};

const GetMetaData = async (metaName: string) => {
    let response;
    try {
        response = await httpGet("/api/meta/" + metaName);
    } catch (error) {
        console.warn(error);
    }
    return response;
};

// TODO(Ginny): need to GET metadata logic refactoring
const GetMetaAll = () => {
    axios
        .all([
            GetMetaData("charan-bag-order-status"),
            GetMetaData("charan-bag-status"),
            GetMetaData("collection-status"),
            GetMetaData("delivery-status"),
            GetMetaData("donation-status"),
            GetMetaData("donor-org"),
            GetMetaData("item-brand"),
            GetMetaData("item-brand-cat"),
            GetMetaData("item-condition"),
            GetMetaData("item-dp-size"),
            GetMetaData("item-dp-size-cat"),
            GetMetaData("item-main-cat"),
            GetMetaData("item-material"),
            GetMetaData("item-measure-size-cat"),
            GetMetaData("seasonality"),
            GetMetaData("item-status"),
            GetMetaData("item-style-cat"),
            GetMetaData("item-sub-cat"),
            GetMetaData("order-status"),
            GetMetaData("payment-type"),
            GetMetaData("returning-status"),
            GetMetaData("settlement-status"),
            GetMetaData("ty-bank"),
            GetMetaData("ty-credit-changed"),
            GetMetaData("kg-selling-status"),
        ])
        .then((response) => {
            MetaData.charanBagOrderStatus = response[0].charanBagOrderStatus;
            MetaData.charanBagStatus = response[1].charanBagStatus;
            MetaData.collectionStatus = response[2].collectionStatus;
            MetaData.deliveryStatus = response[3].deliveryStatus;
            MetaData.donationStatus = response[4].donationStatus;
            MetaData.donorOrg = response[5].donorOrgs;
            MetaData.itemBrand = response[6].itemBrands;
            MetaData.itemBrandCat = response[7].itemBrandCats;
            MetaData.itemCondition = response[8].itemConditions.sort((a: any, b: any) => a.id - b.id);
            MetaData.itemDpSize = response[9].itemDPSizes;
            MetaData.itemDpSizeCat = response[10].itemDPSizeCats;
            MetaData.itemMainCat = response[11].itemMainCats;
            MetaData.itemMaterial = response[12].itemMaterials;
            MetaData.itemMeasureSizeCat = response[13].itemMeasureSizeCats;
            MetaData.itemSeasonality = response[14].seasonalities;
            MetaData.itemStatus = response[15].itemStatus;
            MetaData.itemStyleCat = response[16].itemStyleCats;
            MetaData.itemSubCat = response[17].itemSubCats;
            MetaData.orderStatus = response[18].orderStatus;
            MetaData.paymentType = response[19].paymentType;
            MetaData.returningStatus = response[20].returningStatus;
            MetaData.settlementStatus = response[21].settlementStatus;
            MetaData.typeBank = response[22].tyBanks;
            MetaData.typeCreditChanged = response[23].tyCreditChangeds;
            MetaData.kgSellingStatus = response[24].data.kgSellingStatuses;
            localStorage.setItem("meta", JSON.stringify(MetaData));
        })
        .catch((error) => {
            console.warn(error);
            alert("Meta data를 가져오는 도중 오류가 발생했습니다.");
        });
};

export { GetMetaData, GetMetaAll };
