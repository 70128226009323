import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import MinimalLayout from "../layout/MinimalLayout";

// render - login
const Register = Loadable(lazy(() => import("../pages/authentication/Register")));
const AuthLogin = Loadable(lazy(() => import("../pages/authentication/auth-forms/AuthLogin")));
const AuthRegister = Loadable(lazy(() => import("../pages/authentication/auth-forms/AuthRegister")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "/",
            element: <AuthLogin />,
        },
        {
            path: "authlogin",
            element: <AuthLogin />,
        },
        {
            path: "authregister",
            element: <AuthRegister />,
        },
        {
            path: "login",
            element: <AuthLogin />,
        },
        {
            path: "register",
            element: <Register />,
        },
    ],
};

export default LoginRoutes;
