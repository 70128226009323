import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Button, Stack, Box, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";

// assets
import Google from "../../../assets/images/icons/google.svg";
import Twitter from "../../../assets/images/icons/twitter.svg";
import Facebook from "../../../assets/images/icons/facebook.svg";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../../store/Cookie";
import { Login } from "../../../api/authService";
import { GetMetaAll } from "../../../api/metaService";
import CharanCoreLogo from "../../../assets/images/charan_lockup_core.svg";
import { USER_INFO_COOKIE_KEY } from "../../../constant";

// ==============================|| FIREBASE - SOCIAL BUTTON ||============================== //

const FirebaseSocial = (props: any) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

    const data = {
        id: props.values.email,
        pw: props.values.password,
        provider: "",
        accessToken: "",
        oauthToken: "",
    };

    // -----------------------------

    const googleHandler = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            if (tokenResponse && tokenResponse.access_token.length) {
                data.provider = "google";
                data.oauthToken = tokenResponse.access_token;
                console.log(data);

                Login(data)
                    .then((response) => {
                        if (response.statusCode === 200 && response.accessToken.length) {
                            setCookie(USER_INFO_COOKIE_KEY, response.user, { path: "/" });
                            setCookie("accessToken", response.accessToken, { path: "/" });
                            GetMetaAll();
                            navigate("/dashboard");
                        } else {
                            console.log(response);
                            alert(response.statusMessage);
                        }
                    })
                    .catch((error) => {
                        console.warn(error);
                        alert("로그인중 오류가 발생했습니다.");
                    });
            } else {
                console.warn(tokenResponse);
                alert("구글 액세스 토큰에 문제가 발생했습니다.");
            }
        },
        onError: (err) => {
            console.warn("failed:", err);
            alert(err);
        },
    });

    const twitterHandler = async () => {
        data.provider = "twitter";
        Login(data)
            .then((response) => {
                if (response.statusCode === 200 && response.accessToken.length) {
                    navigate("/dashboard");
                }
            })
            .catch((err) => {
                alert(err.message);
                console.error(err);
            });
    };

    const facebookHandler = async () => {
        data.provider = "facebook";
        Login(data)
            .then((response) => {
                if ((response.statusCode as number) === 200 && response.accessToken.length) {
                    navigate("/dashboard");
                }
            })
            .catch((err) => {
                alert(err.message);
                console.error(err);
            });
    };

    return (
        <Stack
            direction="column"
            spacing={matchDownSM ? 1 : 2}
            justifyContent={matchDownSM ? "space-around" : "space-between"}
            sx={{ "& .MuiButton-startIcon": { mr: matchDownSM ? 0 : 1, ml: matchDownSM ? 0 : -0.5 } }}
        >
            <img src={CharanCoreLogo} />
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={!matchDownSM}
                startIcon={<img src={Google} alt="Google" />}
                // onClick={() => googleHandler(props.values.email, props.values.password)}
                onClick={() => {
                    googleHandler();
                    // if (!data.id.length && !data.pw.length) {
                    //     alert("이메일과 패스워드를 입력하셔야 합니다.");
                    // }
                    // else {
                    //     googleHandler();
                    // }
                }}
            >
                {!matchDownSM && "Google"}
            </Button>
            {/* <Button
                variant="outlined"
                color="secondary"
                fullWidth={!matchDownSM}
                startIcon={<img src={Twitter} alt="Twitter" />}
                onClick={twitterHandler}
                disabled={true}
            >
                {!matchDownSM && "Twitter"}
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={!matchDownSM}
                startIcon={<img src={Facebook} alt="Facebook" />}
                onClick={facebookHandler}
                disabled={true}
            >
                {!matchDownSM && "Facebook"}
            </Button> */}
        </Stack>
    );
};

export default FirebaseSocial;
