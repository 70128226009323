import { Box } from "@mui/material";
import styled, { css } from "styled-components";

interface TableProps {
    bgColor: string;
    borderItem: string;
    widthItem: string;
}

interface TableItemProps {
    bgColor?: string;
    textColor?: string;
    value?: string;
    borderColor?: string;
}

interface ColorBoxProps {
    bgColor?: string;
}

interface TableTDProps {
    width?: string;
}

interface TableTRProps {
    isScanned: boolean;
}

export const ModalBox = styled(Box)`
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1300px;
    background-color: #ffffff;
    box-shadow: none;
    border: 1px solid #a9a9a9;
    // height: 900px;
    overflow: scroll;

    @media (max-width: 1400px) {
        width: 70vw;
    }
`;
export const ModalOuterBox = styled(Box)`
    border-radius: 24px;
    border: 0.5px solid #c4c4c4;
    background: #f6f6f6;
    padding: 32px;
    margin: 24px 40px;
`;

export const ModalContentBox = styled(Box)`
    display: flex;
    padding: 24px 24px 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px solid #c4c4c4;
    background: #fff;
`;

export const ModalContentGrid = styled(Box)`
    max-height: 150px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const StorageIDBox = styled(Box)<{ isSelected: boolean }>`
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    ${({ isSelected }) =>
        isSelected
            ? css`
                  background: var(--P2, #6630e5);
                  div {
                      color: #ffffff;
                  }
              `
            : css`
                  border: 0.5px solid #c4c4c4;
                  div {
                      color: ##404040;
                  }
                  :hover {
                      background: #f4f4f4;
                  }
              `}
    div {
        text-align: center;
        font-family: Noto Sans KR;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        span {
            margin-left: 4px;
            color: #b3b3b3;
        }
    }
`;

export const StorageExportItemListTable = styled("table")`
    margin-top: 5px;
    width: 100% !important;
    border-spacing: 0px;
    border-collapse: collapse;
    border: solid 0.5px #c4c4c4;
    // margin-top: 0.5px;
`;

export const StorageExportItemListTableTH = styled("th")<TableTDProps>`
    background: #f4f4f4;
    width: ${(props) => props.width};
    padding: 5px 5px;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    border: solid 0.5px #c4c4c4;
`;

export const StorageExportItemListTableTR = styled("tr")<{ isScanned: boolean }>`
    display: inline-block;
    width: 100%;
    ${({ isScanned }) =>
        isScanned &&
        css`
            background-color: #e0d6fa;
        `}
`;

export const StorageExportItemListTableTBODY = styled("tbody")<{ isScanned?: boolean }>`
    display: block;
    max-height: 430px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

export const StorageExportItemListTableTD = styled("td")<{ isScanned?: boolean }>`
    width: ${(props) => props.width};
    border: solid 0.5px #c4c4c4;
    padding: 5px 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    ${({ isScanned }) =>
        isScanned
            ? css`
                  span {
                      color: #661fea;
                  }
              `
            : css`
                  span {
                      color: #b3b3b3;
                  }
              `}
`;

export const Title = styled(Box)`
    text-align: center;
    font-size: 25px;
    font-weight: 800;
    color: #661fea;
`;

export const StorageTable = styled("table")<TableProps>`
    border: ${(props) => props.borderItem};
    width: ${(props) => props.widthItem};
    text-align: center;
    background-color: ${(props) => props.bgColor};
    border-spacing: 0px;
`;

export const StorageItem = styled("td")<TableItemProps>`
    background-color: ${(props) => (props.bgColor ? props.bgColor : "none")};
    color: ${(props) => (props.textColor ? props.textColor : "#6C6C6C")};
    border: 1px solid #c4c4c4;
    font-weight: 600;
`;

export const TableSpace = styled("div")<{ flex?: string }>`
    display: flex;
    justify-content: center;
    padding: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    align-items: ${({ flex }) => (flex === "" || flex === undefined || flex === null ? "center" : flex)};
    background: rgba(239, 239, 239, 0.3);
    border: 0.5px solid #a9a9a9;
`;

export const FrontLabel = styled("p")`
    font-size: 14px;
    color: #c4c4c4;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
`;

export const TopSapce = styled("div")`
    display: flex;
    padding: 24px 40px 16px 40px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
`;

export const ModalTable = styled("table")`
    width: 90%;
    margin: 0px auto;
    border-spacing: 0px;
    border-collapse: collapse;
`;

export const TableTH = styled("th")<TableTDProps>`
    border: 0.5px solid #a9a9a9;
    width: ${(props) => props.width};
    color: #6c6c6c;
    background-color: #a9a9a94f;
    padding: 5px 5px;
    font-size: 13px;
    font-weight: 500;
`;

export const TableTD = styled("td")<TableTDProps>`
    border: 0.5px solid #a9a9a9;
    width: ${(props) => props.width};
    padding: 5px 5px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
`;

export const TableTR = styled("tr")`
    background-color: #ffffff;
    color: #6c6c6c;
`;

export const TableScanTR = styled("tr")`
    background-color: #f2f3f9;
    color: #661fea;
`;

export const ButtonBox = styled("div")`
    display: flex;
    justify-content: center;
`;

export const ItemInput = styled("input")`
    width: 0px;
    height: 0px;
    color: transparent;
    border: none;
    font-weight: 500;
    padding: 0px 8px;

    :focus {
        color: transparent;
        border: none;
    }
`;
