import { Box } from "@mui/material";
import styled from "styled-components";

export const ModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    padding: 30px 10px;
    background-color: #ffffff;
    box-shadow: none;
    border: 1px solid #a9a9a9;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalTitle = styled("div")`
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #661fea;
    margin-bottom: 20px;
`;

export const ModalContent = styled("div")`
    width: 400px;
    background-color: #f5f5f5;
    padding: 10px 20px;
    padding-top: 15px;
    margin-bottom: 20px;
`;
