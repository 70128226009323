// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// project import
import menu from "./menu";
// import storage from 'redux-persist/lib/storage/';

import storageSession from "redux-persist/lib/storage/session";

// ==============================|| COMBINE REDUCERS ||============================== //
//local or session
// whitelist -> reducers saving through persist Black does not
const persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: ["menu"],
    // blacklist: ['activeItem', 'activeComponent', 'openDrawer', 'openComponentDrawer' ]
};

const reducers = combineReducers({ menu });

export default persistReducer(persistConfig, reducers);
