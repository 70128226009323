import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";
import { StorageExportItemProps, StorageExportListProps } from "../utils/help";
import { useAlert } from "./alert";

interface StorageExportItem {
    id: string;
    storageExportItem: StorageExportListProps;
    handleRefresh: () => void;
    onClickClose: () => void;
}

export const storageExportState = atom<StorageExportItem | null>({
    key: "storage_export_state",
    default: null,
});

export const useStorageExport = () => {
    const setRecoilState = useSetRecoilState(storageExportState);
    const customAlert = useAlert();

    return useCallback(
        ({
            storageExportItems,
            handleRefresh,
        }: {
            storageExportItems: StorageExportItemProps[];
            handleRefresh: () => void;
        }) => {
            if (storageExportItems.length === 0) {
                customAlert({ message: "반출 처리할 발송건을 선택해주세요." });
            } else {
                const invalid = storageExportItems.find((value) => !value.storageID);
                if (invalid) {
                    customAlert({
                        message:
                            "스토리지에 존재하지 않는 아이템이 있습니다.<br />Item ID : " + invalid.id,
                    });
                } else {
                    const newAlertItem = {
                        id: Math.random().toString(16).slice(2),
                        storageExportItem: storageExportItems.reduce(
                            (storageExportListFromCheckedList, storageExportItem) => {
                                if (
                                    Object.keys(storageExportListFromCheckedList ?? {}).includes(
                                        storageExportItem.storageID
                                    )
                                ) {
                                    storageExportListFromCheckedList[storageExportItem.storageID].push(
                                        storageExportItem
                                    );
                                } else {
                                    storageExportListFromCheckedList[storageExportItem.storageID] = [
                                        storageExportItem,
                                    ];
                                }
                                return storageExportListFromCheckedList;
                            },
                            {} as StorageExportListProps
                        ),
                        handleRefresh,
                        onClickClose: () => {
                            setRecoilState(null);
                        },
                    };
                    setRecoilState(newAlertItem);
                }
            }
        },
        [setRecoilState]
    );
};
