import styled, { css } from "styled-components";
import {
    color,
    ColorProps,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    opacity,
    OpacityProps,
} from "styled-system";

export interface TypoProps
    extends SpaceProps,
        LayoutProps,
        TypographyProps,
        ColorProps,
        FlexboxProps,
        OpacityProps {
    type?:
        | "main_header"
        | "main_header_1"
        | "main_header_2"
        | "sub_header_1"
        | "sub_header_2"
        | "text_primary"
        | "text_secondary"
        | "text_secondary_2"
        | "text_secondary_plus"
        | "text_tertiary"
        | "excp_kor_display_name"
        | "text_secondary_plus";
}

const Typography = styled.div<TypoProps>`
    font-family: "Noto Sans KR", "Happiness Sans Title", "Happiness Sans Bold", "Happiness Sans Regular",
        "Happiness Sans", sans-serif !important;
    color: black;
    ${({ type = "text_primary" }) =>
        type === "main_header"
            ? css`
                  font-family: "Happiness Sans Title" !important;
                  font-size: 32px;
                  line-height: 46px;
              `
            : type === "main_header_1"
            ? css`
                  font-family: "Happiness Sans Title" !important;
                  font-size: 32px;
                  line-height: 40px;
              `
            : type === "main_header_2"
            ? css`
                  font-family: "Happiness Sans Bold" !important;
                  font-size: 32px;
                  line-height: 40px;
              `
            : type === "sub_header_1"
            ? css`
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 36px;
              `
            : type === "sub_header_2"
            ? css`
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 30px;
              `
            : type === "text_primary"
            ? css`
                  font-size: 16px;
                  line-height: 24px;
              `
            : type === "text_secondary"
            ? css`
                  font-size: 12px;
                  line-height: 18px;
              `
            : type === "text_secondary_2"
            ? css`
                  font-size: 12px;
                  line-height: 20px;
              `
            : type === "text_secondary_plus"
            ? css`
                  font-size: 14px;
                  line-height: 20px;
              `
            : type === "text_tertiary"
            ? css`
                  font-size: 11px;
                  line-height: 14px;
              `
            : type === "excp_kor_display_name" &&
              css`
                  font-family: "Happiness Sans Title" !important;
                  font-weight: normal;
                  font-size: 24px;
                  line-height: 30px;
              `}
    max-width: 100%;
    word-break: keep-all;
    word-wrap: break-word;
    ${color}
    ${layout}
    ${typography}
    ${space}
    ${flexbox}
    ${opacity}
`;

export default Typography;
