import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

// scroll bar
import "simplebar/src/simplebar.css";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// apex-chart
import "./assets/third-party/apex-chart.css";

// project import
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";

// redux-persist
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
const persistor = persistStore(store);

ReactDOM.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename="/">
                    <GoogleOAuthProvider clientId="94039833241-bfgooml2gduo1mbslbiu1etrvavfbd4p.apps.googleusercontent.com">
                        <App />
                    </GoogleOAuthProvider>
                </BrowserRouter>
            </PersistGate>
        </ReduxProvider>
    </StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
