import { httpPost } from "./apiService";

const Login = async (data: any) => {
    let response;
    try {
        response = await httpPost("/api/iam/sign-in", data);
    } catch (error) {
        console.warn(error);
    }
    return response;
};

export { Login };
