import { useRoutes } from "react-router-dom";
import { getCookie, removeCookie } from "../store/Cookie";
import { useNavigate } from "react-router-dom";
// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const navigate = useNavigate();
    const hasToken = getCookie("accessToken");
    // if (!hasToken) {
    //     removeCookie("coreUser", { path: "/" });
    //     removeCookie("accessToken", { path: "/" });
    //     navigate("/");
    // }
    return useRoutes(hasToken ? [MainRoutes, LoginRoutes] : [LoginRoutes]);
}
