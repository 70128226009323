import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

interface StorageRegisterItem {
    id: string;
    handleRefresh: () => void;
    onClickClose: () => void;
}

export const storageRegisterState = atom<StorageRegisterItem | null>({
    key: "storage_register_state",
    default: null,
});

export const useStorageRegister = () => {
    const setRecoilState = useSetRecoilState(storageRegisterState);

    return useCallback(
        ({ handleRefresh }: { handleRefresh: () => void }) => {
            const newAlertItem = {
                id: Math.random().toString(16).slice(2),
                handleRefresh,
                onClickClose: () => {
                    setRecoilState(null);
                },
            };
            setRecoilState(newAlertItem);
        },
        [setRecoilState]
    );
};
