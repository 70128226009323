import React from "react";
import { alertState } from "../context/alert";
import { useRecoilValue } from "recoil";
import AlertDialog from "./modal/AlertDialog";

export const Alert = () => {
    const alertData = useRecoilValue(alertState);
    if (!alertData) return <></>;

    return (
        <AlertDialog
            key={alertData.id}
            open
            handleClose={alertData.onClickCancel}
            confirm={alertData.isConfirm}
            handleConfirm={alertData.onClickOk}
            handleBtn={alertData.onClickOk}
        >
            {alertData.message}
        </AlertDialog>
    );
};
