import { useEffect, useState, useRef } from "react";
import { Box, Modal } from "@mui/material";
import PrimaryButton from "../../button/PrimaryButton";
import AlertDialog from "../AlertDialog";
import { PutApi } from "../../../api/apiService";
import WarningImg from "../../../assets/images/warning_storage_export.svg";
import {
    ModalBox,
    TopSapce,
    Title,
    ItemInput,
    ButtonBox,
    ModalOuterBox,
    ModalContentBox,
    StorageIDBox,
    StorageExportItemListTable,
    StorageExportItemListTableTH,
    StorageExportItemListTableTR,
    StorageExportItemListTableTD,
    StorageExportItemListTableTBODY,
} from "./StorageStyled";
import {
    StorageExportItemProps,
    StorageExportListProps,
    KEY_ENTER,
    validateScannedItem,
    scanItem,
} from "../../../utils/help";
import Exit from "../../../assets/icon/exit/ic_exit_24px.svg";
import Typography from "../../../components/Typography";
import { useRecoilValue } from "recoil";
import { storageExportState } from "../../../context/storageExport";
import moment from "moment";

export enum eStorageExportType {
    ITEM_EXPORT,
    DONATION_EXPORT,
    RETURN_EXPORT,
    THREEPL_EXPORT,
    ITEM_INBOUND,
}

const StorageExport = ({
    storageExportListProp,
    open,
    onClose,
    handleRefresh,
}: {
    storageExportListProp: StorageExportListProps;
    open: boolean;
    onClose: any;
    handleRefresh?: any;
}) => {
    const [selectedStorageID, setSelectedStorageID] = useState<string>("");
    const [storageExportList, setStorageExportList] = useState<StorageExportListProps>(
        storageExportListProp ?? {}
    );
    const itemInputRef = useRef<any>();
    const scrollRef = useRef<any>();

    const [showImg, setShowImg] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertConfirm, setAlertConfirm] = useState(false);
    const handleAlertOpen = () => setAlertOpen(true);
    const handleAlertClose = () => setAlertOpen(false);
    const handleConfirm = () => {
        onClose();
        handleAlertClose();
        itemInputRef.current.value = "";
        itemInputRef.current.focus();
    };

    useEffect(() => {
        if (storageExportList && Object.keys(storageExportList).length > 0) {
            setSelectedStorageID(Object.keys(storageExportList).sort()[0]);
        }
    }, [open]);

    const storageSortCriteria = (a: StorageExportItemProps, b: StorageExportItemProps) => {
        const dateA = moment(a.storageImportedAt?.replace("Z", "")).toDate();
        const dateB = moment(b.storageImportedAt?.replace("Z", "")).toDate();

        if (!dateA || !dateB) return 0;
        if (dateA > dateB) return 1;
        if (dateA < dateB) return -1;
        return 0;
    };

    const sortStorageItems = (storageList: StorageExportItemProps[]) => {
        if (!storageList) return [];
        const storageExportListCopy = [...storageList];
        return storageExportListCopy.sort(storageSortCriteria);
    };

    const handleClose = () => {
        if (getScannedItemsCount() > 0) {
            setAlertText(
                "반출처리를 완료하지 않고 이 페이지에서 나가면 <br />입력된 정보가 유실됩니다!"
            );
            setAlertConfirm(true);
            handleAlertOpen();
            return;
        }
        onClose();
        itemInputRef.current.value = "";
        itemInputRef.current.focus();
    };

    // TODO(Soo): get this functions as a props
    const onStorageExportClick = () => {
        const itemIDs = Object.values(storageExportList).flatMap((items) =>
            items.filter((item) => item.scanned).map((item) => item.id)
        );

        PutApi("/api/logis/storage/item/export", { itemIDs: itemIDs })
            .then((response) => {
                setAlertText("총 " + response.itemCnt.toString() + "개 상품이 반출처리 완료되었습니다.");
                setAlertConfirm(false);
                handleAlertOpen();
                handleRefresh();
            })
            .catch((error: any) => {
                console.warn(error);
                setAlertText("반출처리중 오류가 발생했습니다.");
                setAlertConfirm(false);
                handleAlertOpen();
            });
    };

    const handleAlertBtn = () => {
        handleAlertClose();
        if (showImg)
            // 경고문이라서 다이얼로그는 닫히지 않는다.
            setShowImg(false);
        else onClose();
    };

    const itemKeyDownFunction = (e: any) => {
        if (e.keyCode === KEY_ENTER) {
            const scannedItemID = parseInt(e.target.value);
            const isValidItem = validateScannedItem(scannedItemID, selectedStorageID, storageExportList);
            if (isValidItem) {
                scanItem(
                    scannedItemID,
                    selectedStorageID,
                    storageExportList ?? {},
                    setStorageExportList
                );
            } else {
                setShowImg(true);
                setAlertText("반출 대상 상품이 아닙니다!<br />스토리지에 다시 걸어주세요.");
                setAlertConfirm(false);
                handleAlertOpen();
            }
            e.target.value = "";
            e.target.focus();
        }
    };

    const getScannedItemsCount = () => {
        const scannedItems = Object.values(storageExportList).flatMap((items) =>
            items.filter((item) => item.scanned)
        );
        return scannedItems.length;
    };

    const getScannedItemsCountForStorageID = (storageID: string) => {
        return storageExportList[storageID]?.filter((item) => item.scanned).length;
    };

    const getTotalItemsCount = () => {
        return Object.keys(storageExportList)
            .map((key) => storageExportList[key].length)
            .reduce((a, b) => a + b, 0);
    };

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                ref={scrollRef}
                onClick={() => itemInputRef.current?.focus()}
            >
                <ModalBox style={{ maxHeight: "900px", position: "relative" }}>
                    <Box>
                        <TopSapce
                            style={{
                                position: "sticky",
                                width: "100%",
                                top: "0",
                                backgroundColor: "white",
                            }}
                        >
                            <Title>스토리지 반출</Title>
                            <img
                                src={Exit}
                                style={{ width: "30px", height: "30px", cursor: "pointer" }}
                                onClick={handleClose}
                            />
                        </TopSapce>
                        <ModalOuterBox>
                            <Typography fontWeight="500" type="text_secondary_plus">
                                전체 ({getScannedItemsCount()}/{getTotalItemsCount()})
                            </Typography>
                            <Box display="grid" gridTemplateColumns="0.8fr 1.1fr 1.1fr" gap="10px">
                                <ModalContentBox mt="20px">
                                    <Typography fontWeight="700" type="text_secondary_plus">
                                        반출 상품 스토리지
                                    </Typography>
                                    <Typography type="text_secondary" color="#666" mb="10px">
                                        (스캔 완료 상품수 {getScannedItemsCount()} / 반출 스캔할 상품 수{" "}
                                        {getTotalItemsCount()} )
                                    </Typography>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap="10px"
                                        width="100%"
                                        maxHeight="320px"
                                        overflow="scroll"
                                    >
                                        {Object.keys(storageExportList)
                                            .sort()
                                            .map((storageID, idx) => {
                                                return (
                                                    <StorageIDBox
                                                        key={idx}
                                                        isSelected={storageID === selectedStorageID}
                                                        onClick={() => {
                                                            setSelectedStorageID(storageID);
                                                            itemInputRef.current.focus();
                                                        }}
                                                    >
                                                        <div>
                                                            {storageID}{" "}
                                                            <span>
                                                                (
                                                                {getScannedItemsCountForStorageID(
                                                                    storageID
                                                                )}
                                                                /{storageExportList[storageID].length})
                                                            </span>
                                                        </div>
                                                    </StorageIDBox>
                                                );
                                            })}
                                    </Box>
                                </ModalContentBox>
                                <ModalContentBox mt="16px">
                                    <Typography fontWeight="700" type="text_secondary_plus">
                                        구역별 반출 상품 목록 &nbsp;
                                        <span style={{ fontWeight: "500" }}>{selectedStorageID}</span>
                                        &nbsp;&nbsp;
                                        <span style={{ fontWeight: "400", color: "#666" }}>
                                            ({getScannedItemsCountForStorageID(selectedStorageID)}/
                                            {storageExportList[selectedStorageID]?.length})
                                        </span>
                                    </Typography>
                                    <StorageExportItemListTable>
                                        <thead
                                            style={{
                                                display: "block",
                                                width: "100%",
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    width: "100%",
                                                    display: "inline-block",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <StorageExportItemListTableTH width="20%">
                                                    No.
                                                </StorageExportItemListTableTH>
                                                <StorageExportItemListTableTH width="40%">
                                                    Item ID
                                                </StorageExportItemListTableTH>
                                                <StorageExportItemListTableTH width="40%">
                                                    P.O.I
                                                </StorageExportItemListTableTH>
                                            </tr>
                                        </thead>
                                        <StorageExportItemListTableTBODY>
                                            {sortStorageItems(storageExportList[selectedStorageID])
                                                ?.filter((item) => !item.scanned)
                                                .map((item: StorageExportItemProps, index: number) => {
                                                    return (
                                                        <StorageExportItemListTableTR
                                                            isScanned={item.scanned}
                                                            key={"complete_" + item.id}
                                                        >
                                                            <StorageExportItemListTableTD width="20%">
                                                                {index + 1}
                                                            </StorageExportItemListTableTD>
                                                            <StorageExportItemListTableTD width="40%">
                                                                {item.id}
                                                            </StorageExportItemListTableTD>
                                                            <StorageExportItemListTableTD width="40%">
                                                                {item.closetID ?? "-"}
                                                            </StorageExportItemListTableTD>
                                                        </StorageExportItemListTableTR>
                                                    );
                                                })}
                                        </StorageExportItemListTableTBODY>
                                    </StorageExportItemListTable>
                                </ModalContentBox>
                                <ModalContentBox mt="16px">
                                    <Typography fontWeight="700" type="text_secondary_plus">
                                        구역별 반출 완료 상품 목록 &nbsp;
                                        <span style={{ fontWeight: "500" }}>{selectedStorageID}</span>
                                        &nbsp;&nbsp;
                                        <span style={{ fontWeight: "400", color: "#666" }}>
                                            ({getScannedItemsCountForStorageID(selectedStorageID)}/
                                            {storageExportList[selectedStorageID]?.length})
                                        </span>
                                    </Typography>
                                    <StorageExportItemListTable>
                                        <thead
                                            style={{
                                                display: "block",
                                                width: "100%",
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    width: "100%",
                                                    display: "inline-block",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <StorageExportItemListTableTH width="20%">
                                                    No.
                                                </StorageExportItemListTableTH>
                                                <StorageExportItemListTableTH width="40%">
                                                    Item ID
                                                </StorageExportItemListTableTH>
                                                <StorageExportItemListTableTH width="40%">
                                                    P.O.I
                                                </StorageExportItemListTableTH>
                                            </tr>
                                        </thead>
                                        <StorageExportItemListTableTBODY>
                                            {storageExportList[selectedStorageID]
                                                ?.filter((item) => item.scanned)
                                                .map((item: StorageExportItemProps, index: number) => {
                                                    return (
                                                        <StorageExportItemListTableTR
                                                            isScanned={item.scanned}
                                                            key={"complete_" + item.id}
                                                        >
                                                            <StorageExportItemListTableTD width="20%">
                                                                {index + 1}
                                                            </StorageExportItemListTableTD>
                                                            <StorageExportItemListTableTD width="40%">
                                                                {item.id}
                                                            </StorageExportItemListTableTD>
                                                            <StorageExportItemListTableTD width="40%">
                                                                {item.closetID ?? "-"}
                                                            </StorageExportItemListTableTD>
                                                        </StorageExportItemListTableTR>
                                                    );
                                                })}
                                        </StorageExportItemListTableTBODY>
                                    </StorageExportItemListTable>
                                </ModalContentBox>
                                <ItemInput
                                    type="text"
                                    ref={itemInputRef}
                                    onKeyDown={(e) => itemKeyDownFunction(e)}
                                    autoFocus
                                ></ItemInput>
                            </Box>
                        </ModalOuterBox>

                        <Box
                            py="24px"
                            mt="40px"
                            borderTop="0.5px solid #C4C4C4"
                            style={{
                                position: "sticky",
                                width: "100%",
                                bottom: "0",
                                backgroundColor: "white",
                            }}
                        >
                            <ButtonBox>
                                {getScannedItemsCount() > 0 ? (
                                    <PrimaryButton
                                        width="170px"
                                        height="40px"
                                        onClick={onStorageExportClick}
                                    >
                                        처리완료
                                    </PrimaryButton>
                                ) : (
                                    <PrimaryButton width="170px" height="40px" disabled>
                                        처리완료
                                    </PrimaryButton>
                                )}
                            </ButtonBox>
                        </Box>
                    </Box>
                </ModalBox>
            </Modal>

            <AlertDialog
                src={WarningImg}
                showImg={showImg}
                open={alertOpen}
                handleClose={handleAlertClose}
                confirm={alertConfirm}
                handleConfirm={handleConfirm}
                handleBtn={handleAlertBtn}
            >
                {alertText}
            </AlertDialog>
        </Box>
    );
};

const StorageExportModal = () => {
    const storageExport = useRecoilValue(storageExportState);

    if (!storageExport) return <></>;

    return (
        <StorageExport
            open
            onClose={storageExport.onClickClose}
            storageExportListProp={storageExport.storageExportItem ?? {}}
            handleRefresh={storageExport.handleRefresh}
        />
    );
};
export default StorageExportModal;
