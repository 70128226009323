import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

// project import
import { activeItem } from "../../../../../store/reducers/menu";
import { changeHeaderTitle } from "../../../../../store/reducers/menu";

import styled from "styled-components";

const ChildMenuText = styled(Typography)`
    color: #a9a9a9;
    font-size: 18px;
    font-weight: 600;
    padding-left: 5px;
`;
// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }: { item: any; level: any }) => {
    const dispatch = useDispatch();
    const menu = useSelector((state: any) => state.menu);
    const { drawerOpen, openItem } = menu;

    let itemTarget = "_self";
    if (item.target) {
        itemTarget = "_blank";
    }
    let listItemProps: any = {
        component: forwardRef<HTMLInputElement, any>((props, ref) => (
            <Link ref={ref} {...props} to={item.url} target={itemTarget} />
        )),
    };
    if (item?.external) {
        //listItemProps = { component: 'a', href: item.url, target: itemTarget };
        listItemProps = { component: "a", href: item.url, target: itemTarget };
    }

    const itemHandler = (id: any, title: any) => {
        dispatch(activeItem({ openItem: [id] }));
        dispatch(changeHeaderTitle({ headerTitle: [title] }));
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} /> : false;

    const isSelected = openItem.findIndex((id: any) => id === item.id) > -1;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split("/")
            .findIndex((id) => id === item.id);
        // if (currentIndex > -1) {
        //     dispatch(activeItem({ openItem: [item.id] }));
        // }
        // eslint-disable-next-line
    }, []);

    const textColor = "text.primary";
    const iconSelectedColor = "primary.main";

    return (
        <ListItemButton
            {...listItemProps}
            key={item.id}
            disabled={item.disabled}
            onClick={() => itemHandler(item.id, item.title)}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    "&:hover": {
                        bgcolor: "#f6f2fc",
                    },
                    "&.Mui-selected": {
                        bgcolor: "#fff",
                        // borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: "iconSelectedColor",
                        "&:hover": {
                            color: "#f6f2fc",
                            bgcolor: "#f6f2fc",
                        },
                    },
                }),
                ...(!drawerOpen && {
                    "&:hover": {
                        bgcolor: "transparent",
                    },
                    "&.Mui-selected": {
                        "&:hover": {
                            bgcolor: "transparent",
                        },
                        bgcolor: "transparent",
                    },
                }),
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                                bgcolor: "secondary.lighter",
                            },
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: "primary.lighter",
                                "&:hover": {
                                    bgcolor: "primary.lighter",
                                },
                            }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <ChildMenuText
                            variant="h6"
                            sx={{
                                color: isSelected ? "#661FEA !important" : textColor,
                            }}
                        >
                            {item.title}
                        </ChildMenuText>
                    }
                />
            )}

            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
};

export default NavItem;
