import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// project import
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { drawerWidth } from "../../../config";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({
    open,
    handleDrawerToggle,
    window,
}: {
    open: any;
    handleDrawerToggle: any;
    window: any;
}) => {
    const theme = useTheme();
    //const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownMD = false;

    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;

    // header content
    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

    return (
        <MiniDrawerStyled
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
            variant="permanent"
            anchor="left"
            open={open}
            theme={undefined}
        >
            {drawerHeader}
            {drawerContent}
        </MiniDrawerStyled>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object,
};

export default MainDrawer;
