// material-ui
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, /*useMediaQuery,*/ Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getCookie, removeCookie } from "../../../../store/Cookie";
import Button from "@mui/material/Button";

import { styled, Typography } from "@mui/material";
import { USER_INFO_COOKIE_KEY } from "../../../../constant";

const HeaderContent = () => {
    const navigate = useNavigate();
    const HeaderTitle = useSelector((state: any) => state.menu);
    const { headerTitle } = HeaderTitle;

    const coreUser = getCookie(USER_INFO_COOKIE_KEY);

    const tempLogout = () => {
        removeCookie(USER_INFO_COOKIE_KEY, { path: "/" });
        removeCookie("accessToken", { path: "/" });
        navigate("/");
    };

    const [userType] = React.useState(coreUser ? coreUser.coreRole : "");

    return (
        <HeaderFrame>
            <Box sx={{ width: "54%", ml: 1 }}>
                <h1 style={{ fontSize: "38px", margin: "15px 0px" }}>{headerTitle}</h1>
            </Box>
            <InfoBox>
                <LoginInfoFrame>
                    <ClassBox bgcolor={userType === "FM" ? "#FFEA55" : "#000000"}>
                        <MemberClass color={userType === "FM" ? "#000000" : "#FFFFFF"}>
                            {userType}
                        </MemberClass>
                    </ClassBox>
                    <Box sx={{ minWidth: "10px" }} />
                    <Typography>{coreUser !== undefined ? coreUser.id : ""}</Typography>
                    <LogoutBtn sx={{ border: 1, marginLeft: "13px" }} onClick={tempLogout}>
                        logout
                    </LogoutBtn>
                </LoginInfoFrame>
            </InfoBox>
        </HeaderFrame>
    );
};

export default HeaderContent;

const MemberClass = styled(Typography)`
    font-weight: bold;
    text-align: center;
    font-size: 12px;
`;

const ClassBox = styled(Box)`
    width: 60px;
    height: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 1px;
    align-items: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InfoBox = styled(Box)`
    align-items: center;
`;

const LogoutBtn = styled(Button)`
    height: 21px;
    color: grey;
    border: 2px;
    border: 1px grey #c4c4c4;
    border-radius: 22px;
`;

const HeaderFrame = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LoginInfoFrame = styled("div")`
    display: flex;
`;
