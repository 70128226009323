import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Divider, Toolbar, Box } from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }: { open: any; handleDrawerToggle: any }) => {
    const theme = useTheme();
    // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownMD = false;

    const iconBackColor = "grey.100";
    const iconBackColorOpen = "grey.200";

    // common header
    const mainHeader = (
        <>
            <HeaderContent />
        </>
    );

    // app-bar params
    const appBar: any = {
        position: "static",
        color: "inherit",
        elevation: 0,
        sx: {
            //borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        },
    };

    return (
        <>
            {!matchDownMD ? (
                <Box>
                    <AppBarStyled open={open} {...appBar}>
                        {mainHeader}
                    </AppBarStyled>
                    <Divider style={{ borderColor: "#C4C4C4" }}></Divider>
                </Box>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
};

export default Header;
