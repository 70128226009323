const fm = {
    id: "fm",
    // title: 'Bag관리',
    type: "group",
    children: [
        {
            id: "bags",
            idx: 0,
            title: "Bag관리",
            type: "item",
            url: "/bags/order",
            breadcrumbs: false,
            children: [
                {
                    id: "bags-search",
                    title: "Bag 조회",
                    type: "item",
                    url: "/bags/search",
                    breadcrumbs: false,
                },
                {
                    id: "bags-order",
                    title: "Bag 신청관리",
                    type: "item",
                    url: "/bags/order",
                    breadcrumbs: false,
                },
                {
                    id: "bags-ingress",
                    title: "Bag 수거관리",
                    type: "item",
                    url: "/bags/ingress",
                    breadcrumbs: false,
                },
            ],
        },
        {
            id: "items",
            idx: 1,
            title: "상품관리",
            type: "item",
            children: [
                {
                    id: "items-tagging",
                    title: "상품등록",
                    type: "item",
                    url: "/items/tagging",
                },
                {
                    id: "items-search",
                    title: "미전시 상품조회/수정",
                    type: "item",
                    url: "/items/search",
                },
                {
                    id: "items-kg-selling",
                    title: "kg 매입 상품 관리",
                    type: "item",
                    url: "/items/kg-selling",
                },
                {
                    id: "items-taggings",
                    title: "회수상품 관리",
                    type: "item",
                    url: "/items/return",
                },
                {
                    id: "items-donation",
                    title: "기부상품 관리",
                    type: "item",
                    url: "/items/donation",
                },
                {
                    id: "items-display-end",
                    title: "전시종료 상품 관리",
                    type: "item",
                    url: "/items/display-end",
                },
            ],
        },
        {
            id: "sells",
            idx: 2,
            title: "판매관리",
            type: "item",
            children: [
                {
                    id: "sells-order",
                    title: "주문/배송관리",
                    type: "item",
                    url: "/sells/order",
                },
            ],
        },
    ],
};

export default fm;
