import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";

// types
import { openDrawer } from "../../store/reducers/menu";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    // const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownLG = false;
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state: any) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{
                    height: "100vh",
                    flexGrow: 1,
                    p: 3,
                    bgcolor: "#FFFFFF",
                    overflow: "auto",
                }}
            >
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
