import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

// material-ui
import { Box, List, Typography, ListItemButton, ListItemText } from "@mui/material";

// project import
import NavItem from "./NavItem";

import styled from "styled-components";

const ParentMenuButton = styled(ListItemButton)`
    border-top: 0.5px solid #c4c4c4;
`;

const ParentMenuText = styled(Typography)`
    color: #6c6c6c;
    font-size: 21px;
    font-weight: 600;
    padding-left: 5px;
`;

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }: { item: any }) => {
    const [sidemenu, setSidemenu] = useState<boolean[]>([true, true, true, true]);
    const menu = useSelector((state: any) => state.menu);
    const { drawerOpen } = menu;

    const submenuOpen = (id: any) => {
        const temp = sidemenu;
        temp[id] = !temp[id];
        setSidemenu((sidemenu: any) => [...sidemenu, temp]);
    };

    const navCollapse = item.children?.map((menuItem: any) => {
        switch (menuItem.type) {
            case "collapse":
                return (
                    <Typography key={menuItem.idx} variant="caption" color="error" sx={{ p: 2.5 }}>
                        collapse - only available in paid version
                    </Typography>
                );
            case "item":
                return (
                    <div key={menuItem.idx}>
                        <ParentMenuButton
                            onClick={() => submenuOpen(menuItem.idx)}
                            sx={{
                                borderBottom:
                                    menuItem.idx === 2
                                        ? "0.5px solid #C4C4C4 !important"
                                        : sidemenu[menuItem.idx]
                                        ? "0.5px solid #C4C4C4 !important"
                                        : "",
                            }}
                        >
                            <ListItemText
                                primary={
                                    <ParentMenuText
                                        variant="h6"
                                        // sx={{ color: isSelected ? iconSelectedColor : textColor }}
                                    >
                                        {menuItem.title}
                                    </ParentMenuText>
                                }
                            />
                            <span style={{ color: "#D9D9D9" }}>
                                {sidemenu[menuItem.idx] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </span>
                        </ParentMenuButton>
                        {menuItem.children.map((childrenMenu: any) => {
                            return sidemenu[menuItem.idx] ? (
                                <NavItem key={childrenMenu.id} item={childrenMenu} level={1} />
                            ) : (
                                ""
                            );
                        })}
                    </div>
                );

            // return (

            //     <NavItem key={menuItem.idx} item={menuItem} level={1} />
            // );
            default:
                return (
                    <Typography key={menuItem.idx} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Itemssss
                    </Typography>
                );
        }
    });

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {item.title}
                        </Typography>
                        {/* only available in paid version */}
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
            {navCollapse}
        </List>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object,
};

export default NavGroup;
