import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

export interface AlertItem {
    id: string;
    message: string;
    isConfirm: boolean;
    onClickOk: () => void;
    onClickCancel: () => void;
}

export const alertState = atom<AlertItem | null>({
    key: "alert_state",
    default: null,
});

export const useAlert = () => {
    const setRecoilState = useSetRecoilState(alertState);

    return useCallback(
        ({ message, isConfirm }: { message: string; isConfirm?: boolean }): Promise<boolean> => {
            return new Promise((resolve) => {
                const newAlertItem = {
                    id: Math.random().toString(16).slice(2),
                    message,
                    isConfirm: isConfirm || false,
                    onClickOk: () => {
                        resolve(true);
                        setRecoilState(null);
                    },
                    onClickCancel: () => {
                        resolve(false);
                        setRecoilState(null);
                    },
                };
                setRecoilState(newAlertItem);
            });
        },
        [setRecoilState]
    );
};
